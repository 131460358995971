import { Text } from '@village/ui';
import styled from 'styled-components';
import { rem } from 'polished';

import { Image } from 'components/image';

const Header = styled.div`
    display: flex;
    padding: 0 ${rem(20)} ${rem(32)};
    scroll-margin-top: ${rem(78)};
`;

const Divider = styled.div<{ gutter?: boolean }>`
    border-bottom: 1px solid ${({ theme }) => theme.vui.colors.border.divider.default};
    margin: ${({ gutter }) => (gutter ? `0 ${rem(20)}` : 0)};
`;

const AppointmentDetailsContainer = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
    margin: auto;
`;

const AppointmentDetails = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

const CardContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(32)};
    margin: auto;
    max-width: ${rem(720)};
    padding: ${rem(32)} ${rem(20)};
`;

const DateAndLocation = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Address = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ReasonForVisit = styled.div`
    text-align: center;
    color: ${({ theme }) => theme.vui.colors.text.secondary};
`;

const Directions = styled.div`
    margin: ${rem(10)} auto 0;
`;

const DirectionsLink = styled.a`
    display: flex;
    gap: ${rem(4)};
    text-decoration: underline;
    color: ${({ theme }) => theme.vui.colors.text.link.default};
    align-items: center;

    @media (hover: hover) {
        &:hover {
            text-decoration: none;
        }
    }
`;

const List = styled.ul`
    padding-left: ${rem(24)};
`;

const LocationImage = styled(Image)`
    border-radius: 50%;
    border: none;
    object-fit: cover;
    height: ${rem(80)};
    width: ${rem(80)};
`;

const DetailsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(24)};
`;

const Content = styled.div`
    display: grid;
    gap: ${rem(24)};
`;

const HeadingContainer = styled.div`
    display: flex;
    gap: ${rem(8)};
    margin-bottom: ${rem(16)};
    font-family: CeraProBold, arial, sans-serif;
`;

const HeadingTitle = styled(Text).attrs(() => ({ type: 'body1' }))`
    font-family: CeraProBold, arial, sans-serif;
`;

const StickyFooter = styled.div`
    position: sticky;
    bottom: 0;
    width: 100%;
    background-color: white;
    padding: ${rem(16)} ${rem(20)} ${rem(32)};
`;

export {
    Address,
    AppointmentDetailsContainer,
    AppointmentDetails,
    CardContainer,
    Content,
    DateAndLocation,
    Directions,
    DirectionsLink,
    Header,
    HeadingContainer,
    HeadingTitle,
    List,
    LocationImage,
    DetailsWrapper,
    ReasonForVisit,
    Divider,
    StickyFooter,
};
