import styled from 'styled-components';
import { rem } from 'polished';
import { Button as VuiButton, Text, Link as VuiLink } from '@village/ui';

import { device } from 'citymd/constants/device';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(40)};

    @media ${device.maxMobile} {
        gap: 0;
        scroll-margin-top: ${rem(78)};
        height: 100%;
    }
`;

const Button = styled(VuiButton)`
    margin: 0 auto;
    width: ${rem(217)};
    height: ${rem(56)};

    @media ${device.maxMobile} {
        width: 100%;
        max-width: ${rem(341)};
        height: ${rem(56)};
    }
`;

const ButtonWrapper = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
`;

const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${rem(32)};
`;

const Header = styled.div`
    display: inline-flex;
    flex-direction: column;
`;

const Headline = styled(Text)`
    font-family: CeraProBold, arial, sans-serif;
`;

const Link = styled(VuiLink).attrs(() => ({ type: 'body1' }))`
    color: inherit;
    text-decoration: underline;
`;

const StackRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0 ${rem(18)};
`;

const StackCol = styled.div`
    display: flex;
    flex-direction: column;
`;

export { Container, Button, ButtonWrapper, DetailsContainer, Header, Headline, StackCol, StackRow, Link };
