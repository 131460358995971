import { Text } from '@village/ui';

import * as Styled from './styles';

const nextSteps = [
    {
        title: 'Pre-visit forms',
        testId: 'pre-visit-forms',
        bullets: [
            `We’ve sent a text/email link to gather some additional info.
                 Please complete forms prior to arrival to save time at the clinic.
                 Links might take up to five minutes to arrive.`,
        ],
    },
    {
        title: 'Visit guidelines',
        testId: 'visit-guidelines',
        bullets: [`Bring the patient's updated ID and insurance card.`, `Upon arrival, please check in at the front desk.`],
    },
    {
        title: 'Appointment changes?',
        testId: 'appointment-changes',
        bullets: [
            `Life happens. We encourage patients who have missed an appointment to come
                in until end of the day of their scheduled visit. Please note, patients will be treated
                as walk-ins beginning 15 minutes after the appointment start time.`,
            `If you’d like to cancel your visit, you may do so through the details page for this visit.`,
        ],
    },
];

const NextStepsCard = () => {
    return (
        <Styled.DetailsWrapper>
            <Text type="h5" fontWeight={700}>
                What's next?
            </Text>
            <Styled.Content>
                {nextSteps.map((step) => (
                    <div key={step.title} data-testid={step.testId}>
                        <Styled.HeadingContainer>
                            <Styled.HeadingTitle>{step.title}</Styled.HeadingTitle>
                        </Styled.HeadingContainer>
                        <Styled.List>
                            {step.bullets.map((bullet) => (
                                <li key={bullet}>
                                    <Text type="body1">{bullet}</Text>
                                </li>
                            ))}
                        </Styled.List>
                    </div>
                ))}
            </Styled.Content>
        </Styled.DetailsWrapper>
    );
};

export { NextStepsCard };
