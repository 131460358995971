import { useBooking, useCaptureCountlyEvent } from 'hooks';
import { useBookingSteps } from 'citymd/hooks';
import { reasonsForVisit } from 'citymd/utils/reason';
import { StepTitle } from '../step-title';
import * as Styled from './styles';

interface Props {
    reasons: typeof reasonsForVisit;
}

const ReasonForVisit = ({ reasons }: Props) => {
    const { setBookingField, booking } = useBooking();
    const { goForward } = useBookingSteps();
    const { addCountlyEvent } = useCaptureCountlyEvent();

    const handleOptionSelect = (reasonForVisit: (typeof reasonsForVisit)[number]['value']) => {
        setBookingField({ reasonForVisit, firstTimeWorkersCompInjuryCheck: undefined });
        addCountlyEvent({ key: 'selectedReasonForVisit', segmentation: { reasonForVisit } });
        goForward();
    };

    return (
        <Styled.Container>
            <StepTitle>What is the reason for visit?</StepTitle>
            <Styled.Wrapper>
                {reasons.map((option) => (
                    <Styled.ButtonCard
                        $isPreSelected={booking.reasonForVisit === option.value}
                        picture={option.icon}
                        pictureSize="small"
                        key={option.value}
                        onClick={() => handleOptionSelect(option.value)}
                    >
                        {option.text}
                    </Styled.ButtonCard>
                ))}
            </Styled.Wrapper>
        </Styled.Container>
    );
};

export { ReasonForVisit };
