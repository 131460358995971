import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { b2cGraphql } from 'api/b2c-graphql';
import { useAppOptions } from 'hooks';
import { AppointmentApi } from 'types';

interface BookAppointmentInput {
    appointmentId: number;
    departmentId: number;
    schedulingNote: string | null;
    inputParams: {
        reasonForVisit: string;
        firstTimeWorkersCompInjuryCheck: boolean;
    };
}

const query = `#graphql
    mutation BookAppointment($appointmentId: Int!, $departmentId: Int!, $inputParams: BookAppointmentInput!, $schedulingNote: String = null) {
        appointment {
            bookAppointment(appointmentId: $appointmentId, departmentId: $departmentId, inputParams: $inputParams, schedulingNote: $schedulingNote){
                appointmentId
            }
        }
    }
`;

export const useB2cBookAppointment = (): UseMutationResult<AppointmentApi, AxiosError, BookAppointmentInput> => {
    const { auth } = useAppOptions<'authenticated-citymd'>();

    return useMutation<AppointmentApi, AxiosError, BookAppointmentInput>({
        mutationKey: ['b2c-book-appointment'],
        mutationFn: async ({ appointmentId, departmentId, inputParams, schedulingNote }) => {
            const response = await b2cGraphql.post(
                '/graphql',
                {
                    query,
                    variables: {
                        appointmentId,
                        departmentId,
                        inputParams,
                        schedulingNote,
                    },
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${auth.accessToken}`,
                    },
                }
            );

            return response.data.data.bookAppointment;
        },
    });
};
