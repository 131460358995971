import { Fragment, useCallback } from 'react';
import { Text } from '@village/ui';
import { PersonOutlinedIconDeprecated } from '@village/icons';

import { useAppointments, useBookingSteps } from 'citymd/hooks';
import { useAppOptions, useBooking, useCaptureCountlyEvent } from 'hooks';
import { handleBookAppointmentError } from 'utils/appointment';
import { getRelativeDateHeader } from 'citymd/utils/date';
import { formatStreetAddress } from 'utils/address';
import { getReasonAppointmentNote, reasonsForVisit } from 'citymd/utils/reason';
import { StepTitle } from 'citymd/components/step-title';
import { useB2cBookAppointment } from 'authenticated-citymd/hooks/use-b2c-book-appointment';
import { formatDate } from 'utils/date';
import { CalendarIcon } from 'authenticated-citymd/icons/calendar-icon';
import { LocationIcon } from 'authenticated-citymd/icons/location-icon';
import { StethoscopeIcon } from 'authenticated-citymd/icons/stethoscope-icon';
import * as Styled from './styles';

let lastBookAppointmentApiErrorStatus: number | undefined;

const ReviewDetails = () => {
    const { booking, setBookingField } = useBooking();
    const { goForward, goToStart } = useBookingSteps();
    const { addCountlyEvent } = useCaptureCountlyEvent();
    const { refetch } = useAppointments(booking, { enabled: true });
    const { departmentAddress, patient } = useAppOptions<'authenticated-citymd'>();
    const { mutateAsync, isPending, error: bookingError } = useB2cBookAppointment();

    const { address1, address2, city, state, zip } = departmentAddress;

    const onConfirm = useCallback(async () => {
        const { appointment, firstTimeWorkersCompInjuryCheck, reasonForVisit } = booking;

        if (appointment && reasonForVisit) {
            try {
                await mutateAsync({
                    appointmentId: appointment.appointmentid,
                    departmentId: appointment.departmentid,
                    schedulingNote: `---Sched ${formatDate(
                        appointment.dateTimeParsed,
                        'timeTwelveHour'
                    )} (${getReasonAppointmentNote(booking)});`,
                    inputParams: {
                        firstTimeWorkersCompInjuryCheck: !!firstTimeWorkersCompInjuryCheck,
                        reasonForVisit: reasonForVisit,
                    },
                });

                addCountlyEvent({
                    key: 'appointmentBooked',
                    segmentation: {
                        appointmentId: appointment.appointmentid,
                        appointmentTypeId: appointment.appointmenttypeid,
                        appointmentDate: appointment.date,
                        time: appointment.starttime,
                        lastBookAppointmentApiErrorStatus,
                    },
                });

                refetch();
                goForward();
            } catch (error) {
                const apiErrorStatus = handleBookAppointmentError(error, setBookingField, addCountlyEvent);
                /**
                 * This should help us identify the error that
                 * the user was able to recover from and eventually book an appointment
                 */
                lastBookAppointmentApiErrorStatus = apiErrorStatus;
            }
        }
    }, [addCountlyEvent, booking, goForward, mutateAsync, refetch, setBookingField]);

    const bookNewVisit = useCallback(() => {
        refetch();
        goToStart();
    }, [refetch, goToStart]);

    const sendCloseEventToNative = () => {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ type: 'AuthCitymd.Close' }));
    };

    const reasonForVisit = reasonsForVisit.find((reason) => reason.value === booking.reasonForVisit);

    if (!booking.appointment || !reasonForVisit) {
        return null;
    }

    const items = [
        {
            icon: <PersonOutlinedIconDeprecated />,
            title: `${patient.firstName} ${patient.lastName}`,
        },
        {
            icon: <CalendarIcon />,
            title: getRelativeDateHeader(booking.appointment.dateTimeParsed, 'appointmentDate'),
        },
        {
            icon: <LocationIcon />,
            title: address1 ? [formatStreetAddress(address1, address2), city, state, zip].join(', ') : '',
        },
        {
            icon: <StethoscopeIcon />,
            title: reasonForVisit.text,
        },
    ];

    return (
        <Styled.Container>
            {bookingError?.response?.status === 401 ? (
                <Styled.ErrorWrapper>
                    <Styled.ErrorContent>
                        <Styled.ErrorHeading>Your session has expired.</Styled.ErrorHeading>
                        <Text type="body1">Please sign in again to book your appointment.</Text>
                    </Styled.ErrorContent>
                    <Styled.NewVisitButton size="medium" onClick={sendCloseEventToNative}>
                        Close
                    </Styled.NewVisitButton>
                </Styled.ErrorWrapper>
            ) : bookingError ? (
                <Styled.ErrorWrapper>
                    <Styled.ErrorContent>
                        <Styled.ErrorHeading>We're sorry, the selected appointment is no longer available.</Styled.ErrorHeading>
                        <Text type="body1">Please select a different appointment time.</Text>
                    </Styled.ErrorContent>
                    <Styled.NewVisitButton size="medium" onClick={bookNewVisit}>
                        Book new visit
                    </Styled.NewVisitButton>
                </Styled.ErrorWrapper>
            ) : (
                <Fragment>
                    <Styled.DetailsContainer>
                        <Styled.HeadingWrapper>
                            <StepTitle>Review appointment details</StepTitle>
                        </Styled.HeadingWrapper>

                        <Styled.Details>
                            {items.map(({ icon, title }, index) => (
                                <Styled.DetailItem key={index}>
                                    {icon}
                                    <Text type="body1">{title}</Text>
                                </Styled.DetailItem>
                            ))}
                        </Styled.Details>
                    </Styled.DetailsContainer>

                    <Styled.Button onClick={onConfirm} disabled={isPending} size="medium">
                        {isPending ? 'Booking...' : 'Confirm and book'}
                    </Styled.Button>
                </Fragment>
            )}
        </Styled.Container>
    );
};

export { ReviewDetails };
