import { createGlobalStyle } from 'styled-components';

import CeraProThinWoff2 from '../../assets/fonts/CeraPro-Thin.woff2?url';
import CeraProThinWoff from '../../assets/fonts/CeraPro-Thin.woff?url';
import CeraProThinItalicWoff2 from '../../assets/fonts/CeraPro-ThinItalic.woff2?url';
import CeraProThinItalicWoff from '../../assets/fonts/CeraPro-ThinItalic.woff?url';
import CeraProLightWoff2 from '../../assets/fonts/CeraPro-Light.woff2?url';
import CeraProLightWoff from '../../assets/fonts/CeraPro-Light.woff?url';
import CeraProLightItalicWoff2 from '../../assets/fonts/CeraPro-LightItalic.woff2?url';
import CeraProLightItalicWoff from '../../assets/fonts/CeraPro-LightItalic.woff?url';
import CeraProRegularItalicWoff2 from '../../assets/fonts/CeraPro-RegularItalic.woff2?url';
import CeraProRegularItalicWoff from '../../assets/fonts/CeraPro-RegularItalic.woff?url';
import CeraProMediumWoff2 from '../../assets/fonts/CeraPro-Medium.woff2?url';
import CeraProMediumWoff from '../../assets/fonts/CeraPro-Medium.woff?url';
import CeraProMediumItalicWoff2 from '../../assets/fonts/CeraPro-MediumItalic.woff2?url';
import CeraProMediumItalicWoff from '../../assets/fonts/CeraPro-MediumItalic.woff?url';
import CeraProBoldWoff2 from '../../assets/fonts/CeraPro-Bold.woff2?url';
import CeraProBoldWoff from '../../assets/fonts/CeraPro-Bold.woff?url';
import CeraProBoldItalicWoff2 from '../../assets/fonts/CeraPro-BoldItalic.woff2?url';
import CeraProBoldItalicWoff from '../../assets/fonts/CeraPro-BoldItalic.woff?url';
import CeraProRegularWoff2 from '../../assets/fonts/CeraPro-Regular.woff2?url';
import CeraProRegularWoff from '../../assets/fonts/CeraPro-Regular.woff?url';

const CustomGlobalStyles = createGlobalStyle`
    @font-face {
        font-display: swap;
        font-family: CeraProThin;
        font-style: normal;
        font-weight: 100;
        src: url(${CeraProThinWoff2}) format("woff2"),url(${CeraProThinWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProThinItalic;
        font-style: italic;
        font-weight: 100;
        src: url(${CeraProThinItalicWoff2}) format("woff2"),url(${CeraProThinItalicWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProLight;
        font-style: normal;
        font-weight: 300;
        src: url(${CeraProLightWoff2}) format("woff2"),url(${CeraProLightWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProLightItalic;
        font-style: italic;
        font-weight: 300;
        src: url(${CeraProLightItalicWoff2}) format("woff2"),url(${CeraProLightItalicWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProRegularItalic;
        font-style: italic;
        font-weight: 400;
        src: url(${CeraProRegularItalicWoff2}) format("woff2"),url(${CeraProRegularItalicWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProMedium;
        font-style: normal;
        font-weight: 600;
        src: url(${CeraProMediumWoff2}) format("woff2"),url(${CeraProMediumWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProMediumItalic;
        font-style: italic;
        font-weight: 600;
        src: url(${CeraProMediumItalicWoff2}) format("woff2"),url(${CeraProMediumItalicWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProBold;
        font-style: normal;
        font-weight: 800;
        src: url(${CeraProBoldWoff2}) format("woff2"),url(${CeraProBoldWoff}) format("woff")
    }

    @font-face {
        font-display: swap;
        font-family: CeraProBoldItalic;
        font-style: italic;
        font-weight: 800;
        src: url(${CeraProBoldItalicWoff2}) format("woff2"),url(${CeraProBoldItalicWoff}) format("woff")
    }


    @font-face {
        font-display: swap;
        font-family: CeraProRegular;
        font-style: normal;
        font-weight: 400;
        src: url(${CeraProRegularWoff2}) format("woff2"),url(${CeraProRegularWoff}) format("woff")
    }

    /*
    1. Use a more-intuitive box-sizing model.
    */
    *, *::before, *::after {
        box-sizing: border-box;
    }

    /*
    2. Remove default margin
    */
    * {
        margin: 0;
    }

    /*
    Typographic tweaks!
    3. Add accessible line-height
    4. Improve text rendering
    */
    body {
        line-height: 1.5;
        margin: 0;
        -webkit-font-smoothing: antialiased;
    }

    /*
    5. Improve media defaults
    */
    img, picture, video, canvas, svg {
        display: block;
        max-width: 100%;
    }

    /*
    6. Remove built-in form typography styles
    */
    input, button, textarea, select {
        font: inherit;
    }

    /*
    7. Avoid text overflows
    */
    p, h1, h2, h3, h4, h5, h6 {
        overflow-wrap: break-word;
    }

    /*
    8. Create a root stacking context
    */
    #root {
        isolation: isolate;
    }

    html {
        font-family: CeraProRegular, arial, sans-serif;
        scroll-behavior: smooth;
    }

    .dialog-toolbar {
        border: none! important;
    }
`;

export { CustomGlobalStyles };
